import React, { useEffect } from "react";
import { Image } from "../../subcomponents/Elements";
import { useLocation } from "react-router-dom";
import { ampRabbitMQPageEvent,ampRabbitMQSuccessPageEvent, getParam } from "../../../utils";
import { logData } from "../../../utils/services";
import moment from "moment";

export function Subscription({ plansData }) {
  const location = useLocation().search;
  const lang = getParam(location, "lang");
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  // let callback_url = getParam(location, 'callback_url');
  // const identity = getParam(location, "identity");

  // callback_url = callback_url && window.atob(callback_url);
  // console.log(plansData.formData.orderData.plan_name); // "Hello, World!"

  //amplitude
  useEffect(() => {
    const { plan_name, plan_valid, plan_amount, order_id, identity } =
      plansData?.formData?.orderData;

    /* Frontend Logs */
    const lgData = {
      url: hostname + pathname,
      params: location,
      couponcode: couponcode,
      order_data: plansData?.formData?.orderData,
      log_time: new Date().toLocaleString().replace(",", ""),
      log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
    };
    logData({ logData: JSON.stringify(lgData) }).then(() => {});
    /*
    amplitudePageEvent(identity, {
      "Page Type": "PG Page_Success",
      Source: "PG Page_Subscription",
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
    });
    */
    /* amplitude with RabbitMQ */
    ampRabbitMQSuccessPageEvent({
      identity: identity,
      "Page Type": "PG Page_Success",
      Source: "PG Page_Subscription",
      "Plan Name": plan_name,
      "Plan Validity": plan_valid,
      "Plan Value": plan_amount,
      order_id: order_id,
      "Page Language": lang ? lang : "en",
      product_id: plansData?.formData?.orderData?.product_id,
      platform_id: plansData?.formData?.orderData?.platform_id,
    });
  }, [plansData, lang, couponcode, location, pathname, hostname]);

  return (
    <>
      <div className="pdlr-40 mb-20 d-flex" style={{"justify-content": "center"}}>
        <div className="left-box">
          <div className="box-content blur-box">
            <Image src="ribbon.svg" className="ribbon" alt="" />
            <div className="box-content-inner">
              <div className="text-center w-100">
                <img src="https://images.hungama.com/c/uploads_hungama/20240821/20240821104857_new_myMuze.png" alt="" />
              </div>
              <Image src="success.svg" className="success-icon" alt="" />
              <h3 className="title">
                {plansData?.language?.pay_web_success_text1}
              </h3>
              <p className="info">
                {plansData?.language?.pay_web_success_text2}
                {/* Your are now a Hungama Gold member.Enjoy ad free music, podcasts,
              Movies, TV shows & Hungama Originals. */}
              </p>
              <div className="mt-30 w-100">
                {/* <a href={callback_url ? callback_url : "http://hungama.com/payment/success/"} className="button-gold button-padding-80"><i className="icon-Search button-icon"></i>Start Exploring</a> */}
                <a
                  href='https://mymuze.kollywoodhungama.com/'
                  // className="button-gold button-padding-80"
                  style={{
                    "object-fit": "contain",
                    "border-radius": "50px",
                    "background-image": "linear-gradient(68deg, #E20101 24%, #8A0101 84%)",
                    "font-size": "16px",
                    "font-weight": "bold",
                    "font-stretch": "normal",
                    "font-style": "normal",
                    "line-height": "1.25",
                    "letter-spacing": "normal",
                    "text-align": "center",
                    "color": "#fff",
                    "padding": "12px 20px",
                    "max-width": "324px",
                    "width": "100%",
                    "font-weight": "500",
                    "padding": "11px 20px"
                }}
                >
                  {plansData?.language?.pay_web_success_text3}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="right-box">
          <div className="box-content blur-box">
            <div className="box-content-inner">
              <p className="p-t1">
                {plansData?.language?.pay_web_success_text4}
              </p>
              <div className="order-detail">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_success_text5}:
                </div>
                <div className="order-last-col">Hungama Gold</div>
              </div>

              {plansData?.formData?.orderData?.payment_id !== 14 && (
                <div className="order-detail">
                  <div className="order-first-col">
                    {plansData?.language?.pay_web_success_text6}:
                  </div>
                  <div className="order-last-col">
                    <span className="text-white">
                      {plansData?.formData?.orderData?.plan_currency_symbol}
                      {plansData?.formData?.orderData?.txn_amount}
                      {" " + plansData?.formData?.orderData?.plan_name}
                    </span>
                  </div>
                </div>
              )}
              <div className="order-detail" id="rowp-3">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_success_text7}:
                </div>
                <div className="order-last-col">
                  {moment(plansData?.formData?.orderData?.end_date).format(
                    "DD MMM YYYY"
                  )}
                </div>
              </div>
              <div className="order-detail" id="rowp-4">
                <div className="order-first-col">
                  {plansData?.language?.pay_web_success_text8}:
                </div>
                <div className="order-last-col">
                  {plansData?.formData?.orderData?.order_id}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      
      
    </>
  );
}
