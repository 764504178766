import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getParam } from "../../utils";
import Header from "../subpages/TamashaHeader";
import Sidebar from "../subpages/Plans/Sidebar";
import Footer from "../subpages/Footer";
import { plansReducer } from "../../utils/reducers";
import { defaultPlans } from "../../../constants";
import { language3 } from "../../utils/services";
import { checkOrder } from "../../utils/payments";
import { useImmerReducer } from "use-immer";
import { Failed } from "../subpages/fail/tamashafailed";
// import { Pending } from "../subpages/fail/pending";

export function DesktopTamashafailed() {
  const location = useLocation().search;
  const order_id = getParam(location, "order_id");
  const lang = getParam(location, "lang");
  //const product_id = getParam(location, "product_id");
  const identity = getParam(location, "identity");

  let history = useHistory();
  const [plansData, setPlansData] = useImmerReducer(plansReducer, defaultPlans);
  // console.log(plansData);
  const updateMultipleFormData = (obj) => {
    setPlansData({ type: "updateMultipleFormData", payload: obj });
  };
  useEffect(() => {
    checkOrder(order_id).then((res) => {
      if (res.data) {
        console.log(res.data.order_data);
        setPlansData({
          type: "updateMultipleFormData",
          payload: { orderData: res.data.order_data },
        });
      } else {
        history.push("/404");
      }
    });

    //language
    language3(lang).then((lan) => {
      console.log("lang",lan);
      //set default lang for lang convert
      //   setPlansData({
      //     type: "defaultlang",
      //     payload: lan?.default,
      //   });
      setPlansData({
        type: "language",
        payload: lan[lan?.secondLang ? "secondLang" : "default"],
      });
    });
  }, [identity, history, setPlansData, order_id, lang]);
  return (
    <div id="wrapper">
      <Header
        plansData={plansData}
        updateMultipleFormData={updateMultipleFormData}
      />
      <div id="content">
        <div className="main-contentBox-wrapper">
          <Sidebar plansData={plansData} />
          <div className="main-area-container remove-add-container">
            <div className="inner-mid-area">
              <div className="pdlr-40">
                <div className="breadcrumbs-nav flex1">
                  {/*<div className="brdcrms-nav-left">
                                        <button className="btn-38"><a href={`https://payments.hungama.com/retry/${order_id}`}><span className="icon-Back"></span></a></button>
                                        <ul className="brdcms-ul">
                                            <li><a href="!#">Discover</a></li>
                                            <li><a href="!#">Buy Premium</a></li>
                                            <li><a href="!#">Gold Plan</a></li>
                                            <li><a href="!#">Payment Confirmation</a></li>
                                        </ul>
                                    </div>
                                    <div className="brdcrms-nav-right">

                                    </div>
                                    */}
                </div>
              </div>

              {(plansData.formData.orderData.order_status === "5" ||
                plansData.formData.orderData.order_status === 5) && (
                <Failed plansData={plansData} />
              )}
              {/* {(plansData.formData.orderData.order_status === "2" ||
                plansData.formData.orderData.order_status === 2) && (
                <Pending plansData={plansData} />
              )} */}

              {/*
                            <div className="pay-offer-box pdlr-40">
                                <p className="p-t1">Offers for you</p>
                                <div className="hg-music-list-wrapper">
                                    <ul className="hg-music-list top-chart-ul">
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>
                                        <li className="hg_item">
                                            <div className="mb15">
                                                <div className="hg_item_image_wrapper"> <a href="!#"> <Image className="hg_item_img" src="offer.png" alt="topchart1" /> </a> </div>

                                            </div>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                            */}
            </div>
            {/* <Footer lang={plansData?.language} /> */}
          </div>
        </div>
      </div>
      {/* <!--Content Area End--> */}
    </div>
  );
}
