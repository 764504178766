import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import {
  amplitudePageEvent,
  ampRabbitMQPageEvent,
  ampRabbitMQSuccessPageEvent,
  getParam,
} from "../../utils";
import { logData } from "../../utils/services";
import { HM_APP_DEEPLINK } from "../../../constants/index";
import moment from "moment";
import NonAppPurchasePixel from "../nonapppaymentcomponents/NonAppPurchasePixel";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Subscriptionsuccess({
  orderData,
  order_id,
  platform_id,
  product_id,
  lang,
  txtData,
}) {
  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  // const platform_id=new URLSearchParams(location).get('platform_id');
  const offer =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/offer.png";
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const gaEventTracker = useAnalyticsEventTracker("Post Payment Screens");
  //   let history = useHistory();
  // const goToPreviousPath=()=>{
  //     // history.goBack()
  //     if(platform_id==='1'){
  //         window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
  //     }
  // }
  const [isActive, setIsActive] = useState(false);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const identity = new URLSearchParams(location).get("identity");
  // const callback_url = new URLSearchParams(location).get('callback_url');
  let vouchers = [];

  let voucherImages = [offer, offer, offer, offer];
  for (let i = 0; i < voucherImages.length; i++) {
    vouchers.push(
      <SwiperSlide className="planSwiperSlider" key={i}>
        <li className="hg_item">
          <div className="mb15">
            <div className="hg_item_image_wrapper">
              {" "}
              <a href="#!">
                {" "}
                <img
                  className="hg_item_image"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/images/offer.png"
                  }
                  style={{ width: "120px" }}
                  alt="topchart1"
                />{" "}
              </a>{" "}
            </div>
          </div>
        </li>
      </SwiperSlide>
    );
  }
  var plan_currency_symbol = "";
  if (orderData?.plan_currency === "USD") {
    plan_currency_symbol = "$";
  } else {
    plan_currency_symbol = "৳";
  }

  // var ua = navigator.userAgent.toLowerCase();
  // var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  // var ios = /iphone|ipad/.test(ua);
  var URL = "";
  // console.log("sdsdf", typeof orderData?.platform_id, orderData?.platform_id);
  if (orderData?.platform_id === 1) {
    URL = "http://hungama.com/payment/success/";
  } else if (orderData?.platform_id === 8) {
    URL = HM_APP_DEEPLINK;
  } else {
    URL = `${process.env.REACT_APP_HUNGAMA_WEB_URL}`;
  }

  // console.log("URL:", URL);
  // console.log("orderData subscription success-mob:", orderData);
  useEffect(() => {
    if (isLoadingOnce) {
      /*
      amplitudePageEvent(
        orderData?.identity,
        {
          "Page Type": "PG Page_Success",
          Source: "PG Page_Subscription",
          "Plan Name": orderData?.plan_name,
          "Plan Validity": orderData?.plan_valid,
          "Plan Value": orderData?.plan_amount,
          order_id: orderData?.order_id,
          "Page Language": lang ? lang : "en",
        },
        orderData?.product_id,
        orderData?.platform_id
      );
      */
      /* amplitude with RabbitMQ */
      ampRabbitMQSuccessPageEvent({
        identity: orderData?.identity,
        "Page Type": "PG Page_Success",
        Source: "PG Page_Subscription",
        "Plan Name": orderData?.plan_name,
        "Plan Validity": orderData?.plan_valid,
        "Plan Value": orderData?.plan_amount,
        order_id: orderData?.order_id,
        "Page Language": lang ? lang : "en",
        product_id: orderData?.product_id,
        platform_id: orderData?.platform_id,
      });

      /* Frontend Logs */
      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        order_data: orderData,
        log_time: new Date().toLocaleString().replace(",", ""),
        log_type: "FRONTEND_PAYMENT_RESPONSE | Success",
      };
      logData({ logData: JSON.stringify(lgData) }).then(() => {});
    }
    setLoadingOnce(false);
  }, [
    orderData,
    isLoadingOnce,
    lang,
    couponcode,
    hostname,
    location,
    pathname,
  ]);

  /*
    useEffect(() => {
        // const handleTabClose = event => {
        //   event.preventDefault();
    
        //   console.log('beforeunload event triggered');
    
        //   return (event.returnValue = 'Are you sure you want to exit?');
        // };
    
        window.addEventListener('beforeunload', (event)=>{
            event.preventDefault();
            let urlHit=false;
            if(1){
               console.log('url hit', urlHit)
                console.log('beforeunload event triggered');
                urlHit=true;
            }
            if(urlHit===true){
                console.log('url hit true',urlHit)
                return event.returnValue=false;
            }
        });
    
        return () => {
        window.removeEventListener('beforeunload', ()=>{
            console.log('beforeunload event removed');
        });
        };
      }, []);
      */

  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          {/* <span onClick={goToPreviousPath} className="pay-back"><img src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/back-arrow.svg"} alt="arrow" /></span> */}
          <span>{txtData?.pay_mob_tvod_payment_confirmation_head}</span>
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div
              className="pay-mode-box failed-box"
              style={{ position: "relative" }}
            >
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/ribbon-back.svg"
                }
                alt="ribbonback"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0px",
                  right: "0px",
                }}
              />
              {/* <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/hungama-gold.png"
                }
                className="hungama-gold-logo"
                alt="hungama-gold-logo"
              /> */}
              <br />
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/success.png"
                }
                className="payment-failed"
                alt="payment-success"
              />
              <h3 className="payment-failed-title">
                {txtData?.pay_web_success_text1}
              </h3>
              <p className="pf-info">
                {/* Your are now a Hungama Gold member. Enjoy ad free music,
                podcasts, Movies, TV shows & Hungama Originals. */}
                {txtData?.pay_web_success_text2}
              </p>
            </div>
            <div className="pay-mode-box plan-details-box">
              <p className="p-t1">{txtData?.pay_web_success_text4}</p>
              <div className="order-detail">
                <div className="order-first-col">
                  {txtData?.pay_web_success_text5}
                </div>
                <div className="order-last-col">Tamasha Gold</div>
              </div>
              {orderData?.payment_id !== 14 && (
                <div className="order-detail">
                  <div className="order-first-col">
                    {txtData?.pay_web_success_text6}
                  </div>
                  <div className="order-last-col">
                    {plan_currency_symbol} {orderData?.txn_amount}
                  </div>
                </div>
              )}

              {isActive && (
                <>
                  <div className="order-detail" id="rowp-3">
                    <div className="order-first-col">
                      {txtData?.pay_web_success_text7}
                    </div>
                    <div className="order-last-col">
                      {moment(orderData?.end_date).format("DD MMM YYYY")}
                    </div>
                  </div>
                  <div className="order-detail" id="rowp-4">
                    <div className="order-first-col">
                      {txtData?.pay_web_success_text8}
                    </div>
                    <div className="order-last-col">{orderData?.order_id}</div>
                  </div>
                </>
              )}

              <div
                className="order-detail text-center order-detail-btn "
                onClick={() => setIsActive(!isActive)}
              >
                {isActive === true && (
                  <div
                    id="btnViewLess"
                    className="button-view-more-less pointer"
                  >
                    {txtData?.pay_mob_view_less}
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/arrow-up.svg"
                      }
                      alt="arrowup"
                    />
                  </div>
                )}

                {isActive === false && (
                  <div
                    id="btnViewMor"
                    className="button-view-more-less pointer"
                  >
                    {txtData?.pay_mob_view_more}
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/arrow-down.svg"
                      }
                      alt="arrowdown"
                    />
                  </div>
                )}
              </div>
            </div>

            {/*  <div className="pay-offer-box" style={{ position: "sticky" }}>
                            <p className="p-t1">Offers for you</p>
                            <div className="hg-music-list-wrapper">
                                <ul className="hg-music-list top-chart-ul">
                                    <Swiper className="planSwiper">
                                        {vouchers}
                                    </Swiper>
                                </ul>
                                <br></br>
                            </div>

                        </div>
*/}

            {orderData?.payment_id === "9" && (
              <p className="info">
                <i>Please close the browser to return to the app.</i>
              </p>
            )}
          </div>
        </div>
      </div>
      <br></br>
      <div className="footer-action">
        <a
          href={URL}
          onClick={gaEventTracker("start_exploring", "payment successful")}
          className="w-100 goldprimary_btn"
        >
          {orderData?.platform_id !== 8 ? (
            <img
              src={
                process.env.REACT_APP_ASSETS_URL + "/mobile/images/search.svg"
              }
              alt="search"
              className="button-icon"
            />
          ) : (
            ""
          )}
          {/* Start Exploring */}
          {orderData?.platform_id === 8
            ? "Go To Hungama"
            : txtData?.pay_web_success_text3}
        </a>
      </div>
      {/* Start Non App Purchase pixel Code */}
      {orderData?.platform_id === 8 ? (
        <NonAppPurchasePixel orderData={orderData} />
      ) : (
        ""
      )}
      {/* End Non App Purchase pixel Code */}
    </div>
  );
}
